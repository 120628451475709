<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "SetScript.NewSetScript",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-set-script-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-6">
        <label for="setScriptType" class="form-label required">{{
          $t(
            "SetScript.ScriptType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :selectedData="selectedScriptType"
          :allowEmpty="false"
          :data="setScriptTypeData"
          @onChange="onChangeScriptType"
        />
      </div>
      <div class="col col-6">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="setScriptData.isActive"
          />
        </div>
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="Description" class="form-label required"
          >{{
            $t(
              "SetScript.Description",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="setScriptData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="3"
          required
        ></textarea>
      </div>
    </div>
    <div class="row row-cols-1 mt-3 mb-3">
      <div class="col-md-12">
        <label for="ScriptCode" class="form-label">
          {{
            $t(
              "SetScript.ScriptCode",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          <div class="alert-info alert p-0 float-end ms-1 ps-1 pe-1 mb-0">
            {{
              $t(
                "BaseModelFields.SwitchFullScreenMode",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </label>
        <div class="col">
          <v-ace-editor
            v-model:value="setScriptData.script"
            lang="javascript"
            theme="monokai"
            @init="editorInit"
            :options="options"
            id="scriptEditor"
            class="h-300"
          />
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import $ from "jquery";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";

import "ace-builds/src-noconflict/mode-javascript";

import "ace-builds/src-noconflict/ext-language_tools";
//import beautifier from 'ace-builds/src-noconflict/ext-beautify'

import "ace-builds/src-noconflict/snippets/javascript";

import "ace-builds/src-noconflict/ext-searchbox";

import ace from "ace-builds";

import workerJsUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJsUrl);

export default {
  name: "CustomObjectNewSetScript",
  components: {
    VAceEditor,
  },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      scriptEditor: null,
      setScriptData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
        view: 1,
        script: "$(function(){\n\n\n});",
      },
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      detailForwardOrEditForwardType: false,
      isSuccess: false,
      setScriptTypeData: [
        {
          key: "1",
          value: this.$t(
            "SetScript.ScriptTypeNewRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "SetScript.ScriptTypeEditRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "SetScript.ScriptTypeListRecords",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "SetScript.ScriptTypeDetailRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "SetScript.ScriptTypeKanban",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "6",
          value: this.$t(
            "SetScript.ScriptTypeCalendar",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "7",
          value: this.$t(
            "SetScript.ScriptTypeMap",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "8",
          value: this.$t(
            "SetScript.ScriptTypeTree",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedScriptType: [
        {
          key: "1",
          value: this.$t(
            "SetScript.ScriptTypeNewRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeScriptType: function (selected) {
      this.setScriptData.view = selected.key;
    },
    onClear() {
      this.setScriptData = {
        isActive: true,
        customObjectPublicId: this.setScriptData.customObjectPublicId,
        view: this.setScriptData.view,
        script: "$(function(){\n\n\n});",
        customObjectFormulaName: this.setScriptData.customObjectFormulaName,
      };

      $(".form-set-script-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-set-script-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-SetScriptNew", { ...this.setScriptData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/SetScripts/" + this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.scriptEditor = ace.edit("scriptEditor");
    this.scriptEditor.commands.addCommand(this.$root.ace.commands.fullScreen);
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.setScriptData.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.setScriptData.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
};
</script>
